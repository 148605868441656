import { AtSymbolIcon, CheckIcon, ClipboardIcon, PhoneIcon } from "@icons";
import { useSensitiveProfileInfo } from "@modules/directory/hooks"
import { ProfileCard } from "@modules/directory/types";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useState } from "react";

export default function Profile({ person, copiedTooltip, handleCopyClick }: {
    person: ProfileCard,
    copiedTooltip: string | null,
    handleCopyClick: (e: any, datum: any) => void
}) {
    const { data: sensitiveData, isLoading } = useSensitiveProfileInfo(person.id)

    return (
        <div className='flex flex-col gap-2'>
            <div className='flex flex-col gap-2 border border-neutral-70 rounded-xl p-2'>
                {/* Email section */}
                {person.email && (
                    <div className='p-2 rounded-xl relative flex items-center justify-between'>
                        <AnimatePresence>
                            {copiedTooltip === person.email && (
                                <motion.div
                                    initial={{ opacity: 0, left: 50, top: -10 }}
                                    animate={{ opacity: 1, left: 50, top: -35 }}
                                    exit={{ opacity: 0, left: 50, top: -10 }}
                                    className='absolute border-2 border-green-300 bg-neutral-90 p-2 rounded-lg shadow-lg z-50 flex gap-2 items-center'
                                >
                                    Copied to clipboard
                                    <CheckIcon className='h-5 text-green-500' />
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <div className='flex flex-col gap-1'>
                            <span className='font-semibold'>Email</span>
                            {person.email}
                        </div>
                        <div className='flex gap-2'>
                            <div
                                className='cursor-pointer hover:text-primary-40 flex items-center gap-1'
                                onClick={(e) => handleCopyClick(e, person.email)}
                            >
                                <ClipboardIcon className='h-5 w-5' />
                            </div>
                            <a href={`mailto:${person.email}`}>
                                <AtSymbolIcon className='h-5 w-5 hover:text-primary-40' />
                            </a>
                        </div>
                    </div>
                )}

                {/* Phone section */}
                {sensitiveData?.phone_number && (
                    <div className='p-2 rounded-xl relative flex items-center justify-between'>
                        <AnimatePresence>
                            {copiedTooltip === sensitiveData.phone_number && (
                                <motion.div
                                    initial={{ opacity: 0, left: 50, top: -10 }}
                                    animate={{ opacity: 1, left: 50, top: -35 }}
                                    exit={{ opacity: 0, left: 50, top: -10 }}
                                    className='absolute border-2 border-green-300 bg-neutral-90 p-2 rounded-lg shadow-lg z-50 flex gap-2 items-center'
                                >
                                    Copied to clipboard
                                    <CheckIcon className='h-5 text-green-500' />
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <div className='flex flex-col gap-1'>
                            <span className='font-semibold'>Phone</span>
                            {sensitiveData.phone_number}
                        </div>
                        <div className='flex gap-2'>
                            <div
                                className='cursor-pointer hover:text-primary-40 flex items-center gap-1'
                                onClick={(e) => handleCopyClick(e, sensitiveData.phone_number)}
                            >
                                <ClipboardIcon className='h-5 w-5' />
                            </div>
                            <a href={`tel:${sensitiveData.phone_number}`}>
                                <PhoneIcon className='h-5 w-5 hover:text-primary-40' />
                            </a>
                        </div>
                    </div>
                )}
            </div>

            <div className='flex gap-2 border border-neutral-70 rounded-xl p-2 justify-between'>
                <div className='flex flex-col'>
                    <span className='font-semibold'>{person.title}{person.subtitle ? ', ' + person.subtitle : ''}</span>
                    <span>{person.directory_location_filters?.join(', ')}</span>
                    <span>{person.organization.name}</span>
                </div>
                {person.organization.thumbnail_img ? (
                    <div className='justify-end flex items-center'>
                        <div>
                            <img src='/images/user_gray.jpg' alt={person.organization.name} className='h-16 rounded mr-2' />
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    )
}