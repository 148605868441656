import callAPI from 'services';
import { directoryEndpoints } from 'services/api';
import {
	DirectoryProfile,
	DirectoryMember,
	ProfileCard,
	SensitiveInfo,
	ViewableDirectory,
	UserDirectory, OrganizationDirectory
} from '../types';
import { TODO } from '@types';
import { DirectoryGroup } from '../types/Directory';


export async function directory() {
	return await callAPI<TODO>(directoryEndpoints.directory());
}


export async function fetchDirectoryGroups() {
	return await callAPI<Array<DirectoryGroup>>(directoryEndpoints.directoryGroups());
}


export async function fetchDirectoryGroup(groupId: number) {
	return await callAPI<Array<DirectoryProfile>>(directoryEndpoints.fetchDirectoryGroup(groupId));
}

export async function fetchDirectoryDetails(groupId: number) {
	return await callAPI<Array<DirectoryMember>>(directoryEndpoints.directoryGroupDetails(groupId))
}

export async function fetchDirectoryMembers(groupId: number) {
	return await callAPI<Array<DirectoryMember>>(directoryEndpoints.directoryGroupMembers(groupId))
}


export async function fetchMyContacts(daysBack?: number) {
	return await callAPI<TODO>(directoryEndpoints.myContacts(daysBack));
}


export async function deleteRecentContact(contactId: number) {
	return await callAPI<TODO>(directoryEndpoints.myContact(contactId));
}


export async function fetchProfileCard(profileId: number) {
	return await callAPI<ProfileCard>(directoryEndpoints.profileCard(profileId));
}

export async function fetchSensitiveProfileCardInfo(profileId: number) {
	return await callAPI<SensitiveInfo>(directoryEndpoints.profileCardSensitive(profileId))
}

export async function fetchViewableDirectories(): Promise<Array<ViewableDirectory>> {
	return await callAPI(directoryEndpoints.viewableDirectories());
}

export async function fetchUserDirectories(): Promise<Array<UserDirectory>> {
	return await callAPI(directoryEndpoints.userDirectories());
}

export async function fetchOrganizationDirectories(): Promise<Array<OrganizationDirectory>> {
	return await callAPI(directoryEndpoints.orgDirectories());
}


export async function fetchOrganizationDirectoryProfiles(organizationId: number) {
	return await callAPI<Array<DirectoryMember>>(directoryEndpoints.organizationDirectoryProfiles(organizationId));
}

export async function fetchSavedFilters() {
	return await callAPI<Array<Filter>>(directoryEndpoints.savedFilters());
}

export type Filter = { id: number, name: string, filters: Record<string, Array<string>> }

export async function saveFilter(payload: Omit<Filter, 'id'>) {
	console.log('payload', payload);
	return await callAPI<void>(directoryEndpoints.savedFilters(), {
		method: 'POST',
		data: payload
	});
}

export async function deleteFilter(filterId: number) {
	return await callAPI<void>(directoryEndpoints.savedFilter(filterId), {
		method: 'DELETE'
	});
}

export type Note = {
	id: number;
	title: string;
	content: string;
	profile: number;
	created: string;
	modified: string;
}

export async function fetchProfileNotes(profileId: number) {
	return await callAPI<Array<Note>>(directoryEndpoints.profileNotes(profileId));
}

export async function createProfileNote(
	profileId: number,
	note: Omit<Note, 'id' | 'profile' | 'created' | 'modified'>
): Promise<Note> {
	return await callAPI<Note>(directoryEndpoints.profileNotes(profileId), {
		method: 'POST',
		data: note
	});
}


export async function deleteProfileNote(
	profileId: number,
	noteId: number
) {
	return await callAPI<void>(directoryEndpoints.profileNote(profileId, noteId), {
		method: 'DELETE'
	});
}

export async function updateProfileNote(
	profileId: number,
	noteId: number,
	updatedNote: Omit<Note, 'id' | 'profile' | 'created' | 'modified'>
) {
	return await callAPI<Note>(directoryEndpoints.profileNote(profileId, noteId), {
		method: 'PUT',
		data: updatedNote
	});
}