import { useMutation, useQuery, useQueryClient } from '@hooks';
import {
	directory,
	fetchDirectoryGroups,
	fetchDirectoryGroup,
	fetchMyContacts,
	deleteRecentContact,
	fetchProfileCard,
	fetchDirectoryMembers,
	fetchSensitiveProfileCardInfo,
	fetchViewableDirectories, fetchOrganizationDirectories, fetchUserDirectories,
	fetchOrganizationDirectoryProfiles,
	fetchSavedFilters,
	saveFilter,
	Filter,
	deleteFilter,
	fetchProfileNotes,
	updateProfileNote,
	Note,
	deleteProfileNote,
	createProfileNote
} from '../api';
import { TODO } from '@types';
import { useDispatch } from 'react-redux';
import { changePinnedCard } from 'store/slices';


/**
 * Meta endpoint for directory app
 */
export function useDirectory() {
	return useQuery({
		queryKey: directoryKeys.meta(),
		queryFn: directory,
	});
}


/**
 * Fetches the directories that the user has access to
 *
 * @param daysBack - the number of days back to fetch directories for
 *
 */
export function useDirectoryGroups() {
	return useQuery({
		queryKey: directoryKeys.directoryGroups(),
		queryFn: () => fetchDirectoryGroups(),
	});
}

export function useDirectoryMembers(groupId: number) {
	return useQuery({
		queryKey: directoryKeys.directoryMembers(groupId),
		queryFn: () => fetchDirectoryMembers(groupId)
	})
}


/**
 * Fetches the profiles in a directory
 *
 * @param groupId - the id of the directory group to fetch profiles for
 *
 */
export function useDirectoryGroup(groupId: number) {
	return useQuery({
		queryKey: directoryKeys.directoryGroup(groupId),
		queryFn: () => fetchDirectoryGroup(groupId),
	});
}

/**
 * Fetches the profile card for a given profile
 *
 * @param profileId - the id of the profile to fetch the card for
 *
 */
export function useProfileCard() {
	const dispatch = useDispatch()

	return useMutation({
		mutationFn: (profileId: number) => fetchProfileCard(profileId),
		onSuccess: (data) => {
			dispatch(changePinnedCard({ type: 'directory', data }))
		}
	});
}

export function useSensitiveProfileInfo(profileId: number) {
	return useQuery({
		queryKey: directoryKeys.sensitiveInfo(profileId),
		queryFn: () => fetchSensitiveProfileCardInfo(profileId),
		retry: 1
	})
}

/**
 * Removes a contact from the user's recently viewed contacts
 *
 */
export function useDeleteRecentContact() {
	return useMutation({
		mutationFn: (contactId: number) => deleteRecentContact(contactId)
	});
}


export function useViewableDirectories() {
	return useQuery({
		queryKey: directoryKeys.viewableDirectories(),
		queryFn: () => fetchViewableDirectories()
	})
}


export function useUserDirectories() {
	return useQuery({
		queryKey: directoryKeys.userDirectories(),
		queryFn: () => fetchUserDirectories()
	})
}

export function useOrgDirectories() {
	return useQuery({
		queryKey: directoryKeys.orgDirectories(),
		queryFn: () => fetchOrganizationDirectories()
	})
}

export function useOrganizationDirectoryProfiles(organizationId: number) {
	return useQuery({
		queryKey: directoryKeys.orgDirectoryProfiles(organizationId),
		queryFn: () => fetchOrganizationDirectoryProfiles(organizationId)
	})
}

export function useSavedFilters() {
	return useQuery({
		queryKey: directoryKeys.savedFilters(),
		queryFn: () => fetchSavedFilters()
	})
}

export function useCreateSavedFilter() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (filter: Omit<Filter, 'id'>) => saveFilter(filter),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: directoryKeys.savedFilters() });
		}
	})
}

export function useDeleteSavedFilter() {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (filterId: number) => deleteFilter(filterId),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: directoryKeys.savedFilters() });
		}
	})
}

export function useProfileNotes(profileId: number) {
	return useQuery({
		queryKey: directoryKeys.profileNotes(profileId),
		queryFn: () => fetchProfileNotes(profileId)
	})
}

export function useCreateProfileNote(profileId: number) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (note: Omit<Note, 'id' | 'profile' | 'created' | 'modified'>) => createProfileNote(profileId, note),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: directoryKeys.profileNotes(profileId) });
		}
	})
}

export function useUpdateProfileNote(profileId: number) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (note: Omit<Note, 'profile' | 'created' | 'modified'>) => updateProfileNote(profileId, note.id, note),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: directoryKeys.profileNotes(profileId) });
		}
	})
}


export function useDeleteProfileNote(profileId: number) {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (noteId: number) => deleteProfileNote(profileId, noteId),
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: directoryKeys.profileNotes(profileId) });
		}
	})
}


export const directoryKeys = {
	all: ['directory'] as const,

	meta: () => [...directoryKeys.all, 'meta'] as const,

	directoryGroups: (daysBack?: number) => [...directoryKeys.all, 'groups'] as const,
	directoryGroup: (groupId: number | undefined) => [...directoryKeys.all, { 'group': groupId }] as const,

	directoryMembers: (groupId: number) => [...directoryKeys.directoryGroup(groupId), 'members'] as const,

	contacts: () => [...directoryKeys.all, 'contacts'] as const,
	card: () => [...directoryKeys.all, 'card'] as const,

	recentContacts: (daysBack?: number) => [...directoryKeys.contacts(), 'recent', daysBack] as const,
	profileCard: () => [...directoryKeys.card(), 'profile'] as const,
	sensitiveInfo: (profileId: number) => [...directoryKeys.profileCard(), { 'id': profileId }],

	directories: () => [...directoryKeys.all, 'directories'] as const,

	viewableDirectories: () => [...directoryKeys.directories(), 'viewable'] as const,
	userDirectories: () => [...directoryKeys.directories(), 'user'] as const,
	orgDirectories: () => [...directoryKeys.directories(), 'org'] as const,
	orgDirectoryProfiles: (organizationId: number) => [...directoryKeys.orgDirectories(), 'profiles', { organizationId: organizationId }] as const,

	savedFilters: () => [...directoryKeys.all, 'savedFilters'] as const,

	profileNotes: (profileId: number) => [...directoryKeys.profileCard(), 'notes', { 'id': profileId }] as const,
};
