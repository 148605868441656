import { PlusIcon } from "@icons"
import { Note } from "@modules/directory/api"
import { useCreateProfileNote, useDeleteProfileNote, useProfileNotes } from "@modules/directory/hooks"
import { ProfileCard } from "@modules/directory/types"
import { format } from "date-fns"
import { MoreHorizontal, Pencil, Trash2, Search, Filter, ArrowUpDown } from "lucide-react"
import { Popover } from '@headlessui/react'
import { useState } from 'react'

export default function Notes({ person, setOpenNote }: { person: ProfileCard, setOpenNote: (note: Note) => void }) {
    const { data: notes, isLoading } = useProfileNotes(person.id);
    const { mutateAsync: createNote } = useCreateProfileNote(person.id);
    const { mutate: deleteNote } = useDeleteProfileNote(person.id);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortOrder, setSortOrder] = useState<'newest' | 'oldest'>('newest');

    if (isLoading) return <div>Loading...</div>
    if (!notes) return null

    function handleCreateNoteClick() {
        createNote({ title: 'Untitled Note', content: '' }).then((note) => {
            setOpenNote(note);
        })
    }

    function handleDeleteNote(note: Note) {
        deleteNote(note.id);
    }

    const filteredNotes = notes
        .filter(note =>
            note.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
            note.content.toLowerCase().includes(searchQuery.toLowerCase())
        )
        .sort((a, b) => {
            const dateA = new Date(a.created).getTime();
            const dateB = new Date(b.created).getTime();
            return sortOrder === 'newest' ? dateB - dateA : dateA - dateB;
        });

    return (
        <div className="h-full w-full flex flex-col">
            {notes.length === 0 ? (
                <div className="p-2 rounded-xl relative">
                    <div className="flex flex-col gap-1 text-center">
                        No notes
                    </div>
                </div>
            ) : (
                <div className="flex flex-col h-full">
                    {/* Search and Filter - Fixed at top */}
                    <div className="flex items-center gap-2 p-2 mb-2">
                        <div className="relative flex-1">
                            <Search className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-neutral-60" />
                            <input
                                type="text"
                                placeholder="Search notes..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full pl-9 pr-4 py-2 border border-neutral-80 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary-40"
                            />
                        </div>
                        <Popover className="relative">
                            <Popover.Button className="p-2 hover:bg-neutral-95 rounded-lg transition-colors border border-neutral-80">
                                <ArrowUpDown className="h-5 w-5 text-neutral-60" />
                            </Popover.Button>

                            <Popover.Panel className="absolute right-0 z-10 mt-2 w-48 rounded-xl bg-neutral-95 border shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="py-1">
                                    <button
                                        onClick={() => setSortOrder('newest')}
                                        className={`flex w-full items-center px-4 py-2 text-sm ${sortOrder === 'newest' ? 'text-primary-40' : 'text-gray-700'} hover:bg-neutral-95`}
                                    >
                                        Newest first
                                    </button>
                                    <button
                                        onClick={() => setSortOrder('oldest')}
                                        className={`flex w-full items-center px-4 py-2 text-sm ${sortOrder === 'oldest' ? 'text-primary-40' : 'text-gray-700'} hover:bg-neutral-95`}
                                    >
                                        Oldest first
                                    </button>
                                </div>
                            </Popover.Panel>
                        </Popover>
                    </div>

                    {/* Scrollable Notes List */}
                    <div className="flex-1 overflow-y-auto px-2">
                        <div className="flex flex-col gap-2 pb-20">
                            {filteredNotes.map((note) => (
                                <button
                                    key={note.id}
                                    className="rounded-xl relative w-full text-left"
                                    onClick={() => setOpenNote(note)}
                                >
                                    <div className="flex items-center justify-between border border-neutral-80 p-2 rounded-xl">
                                        <div className="flex flex-col">
                                            <span className="font-semibold">{note.title}</span>
                                            <span>Added on {format(note.created, "MMM d, yyyy")}</span>
                                        </div>
                                        <div className="p-2 items-center">
                                            <Popover className="relative">
                                                <Popover.Button className="p-2 hover:bg-neutral-95 rounded-lg transition-colors">
                                                    <MoreHorizontal className="h-6 w-6 text-neutral-60" />
                                                </Popover.Button>

                                                <Popover.Panel className="absolute right-0 z-10 mt-2 w-48 rounded-xl bg-neutral-95 border shadow-lg ring-1 ring-black ring-opacity-5">
                                                    <div className="py-1">
                                                        <button
                                                            onClick={() => setOpenNote(note)}
                                                            className="flex w-full items-center gap-2 px-4 py-2 text-sm text-neutral hover:bg-neutral-80"
                                                        >
                                                            <Pencil className="h-4 w-4" />
                                                            Edit
                                                        </button>
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleDeleteNote(note)
                                                            }}
                                                            className="flex w-full items-center gap-2 px-4 py-2 text-sm text-red-600 hover:bg-neutral-80"
                                                        >
                                                            <Trash2 className="h-4 w-4" />
                                                            Delete
                                                        </button>
                                                    </div>
                                                </Popover.Panel>
                                            </Popover>
                                        </div>
                                    </div>
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
            )}

            {/* Fixed Plus Button */}
            <button
                onClick={handleCreateNoteClick}
                className="fixed bottom-6 right-6 text-white">
                <PlusIcon className="h-10 w-10 bg-primary-40 rounded shadow-lg hover:bg-primary-50 transition-colors" />
            </button>
        </div>
    )
}