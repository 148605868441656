import { Column, Row, Table } from '@tanstack/react-table';
import { Button } from 'components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'components/ui/dropdown-menu';
import { Tooltip, TooltipContent, TooltipTrigger } from 'components/ui/tooltip';
import { saveAs } from 'file-saver';
import { ArrowRightFromLine, Settings as Gear } from 'lucide-react';
import { ReactNode } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

type OnClickSettingItem<T> = (table: Table<T>, rows: Array<Row<T>>, columns: Array<Column<T>>) => void;

export type SettingsOption<T> = {
    onClick: OnClickSettingItem<T>;
    label: string;
    icon: ReactNode;
};

interface Props<T> {
    table: Table<T>;
    options?: Array<SettingsOption<T>>;
}

const Settings = <T,>({ table, options }: Props<T>) => {
    if (!options || options.length < 1) return null;

    function handleSettingItemClick(onClick: OnClickSettingItem<T>) {
        onClick(table, table.getPreSortedRowModel().rows, table.getAllColumns());
    }

    return (
        <div className="flex gap-2">
            {options.map((option) => (
                <Tooltip>
                    <TooltipTrigger asChild>
                        <Button
                            className="[&_svg]:size-6"
                            size={'icon'}
                            variant={'ghost'}
                            key={option.label}
                            onClick={() => handleSettingItemClick(option.onClick)}
                        >
                            {option.icon}
                        </Button>
                    </TooltipTrigger>
                    <TooltipContent align="center" side="top">
                        {option.label}
                    </TooltipContent>
                </Tooltip>
            ))}
        </div>
    );
};

export default Settings;
