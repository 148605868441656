import React, { useEffect, useState, useCallback } from 'react';
import { ProfileCard } from '@modules/directory/types';
import Profile from './components/Profile';
import Notes from './components/Notes';
import { Note } from '@modules/directory/api';
import { useUpdateProfileNote } from '@modules/directory/hooks';
import { debounce } from 'lodash';
import { CheckCircle2, ChevronLeft } from 'lucide-react';
import { motion } from 'framer-motion';

type DirectoryPinnedCardProps = {
	person: ProfileCard;
}

export default function DirectoryPinnedCard(props: DirectoryPinnedCardProps) {
	const [tab, setTab] = React.useState<'profile' | 'notes'>('profile');
	const [copiedTooltip, setCopiedTooltip] = React.useState<string | null>(null);
	const { person } = props;
	const [openNote, setOpenNote] = React.useState<Note>();
	const [titleError, setTitleError] = useState(false);
	const { mutate: updateNote } = useUpdateProfileNote(person.id);

	useEffect(() => {
		if (!copiedTooltip) return;
		setTimeout(() => {
			setCopiedTooltip(null);
		}, 3000);
	}, [copiedTooltip]);

	const handleCopyClick = (e: any, datum: any) => {
		navigator.clipboard.writeText(datum);
		setCopiedTooltip(datum);
	};
	const [showSaved, setShowSaved] = useState(false);

	const debouncedUpdate = useCallback(
		debounce((note: Note) => {
			if (!note.title.trim()) {
				setTitleError(true);
				return;
			}
			setTitleError(false);
			updateNote(note);
			setShowSaved(true);
			setTimeout(() => setShowSaved(false), 2000);
		}, 500),
		[updateNote]
	);

	if (openNote) {
		return (
			<div className="flex flex-col gap-4 p-4 relative h-full">
				<div className="flex flex-col h-full">
					<div className="flex items-center gap-4 pt-1 pb-2 border-b border-neutral-80">
						<button
							onClick={() => setOpenNote(undefined)}
							className="p-2 hover:bg-neutral-90 rounded-lg transition-colors"
						>
							<ChevronLeft className="h-5 w-5 text-neutral-60" />
						</button>
						<div className="flex gap-2 items-center h-16">
							<span className="font-semibold">Note on</span>
							<div className="flex gap-2 items-center text-neutral-60 border border-neutral-80 rounded-lg px-2 py-1">
								<span>
									<img src={person.img_url ?? '/images/user_gray.jpg'} alt={`${person.first_name} ${person.last_name}`} className="w-5 h-5 rounded-full" />
								</span>
								<span>{person.first_name} {person.last_name}</span>
							</div>
						</div>
					</div>

					<div className="flex flex-col gap-4 flex-1">
						<div className="flex flex-col gap-1">
							<input
								type="text"
								value={openNote.title}
								onChange={(e) => {
									const updatedNote = { ...openNote, title: e.target.value };
									setOpenNote(updatedNote);
									if (e.target.value.trim()) {
										setTitleError(false);
									}
									debouncedUpdate(updatedNote);
								}}
								className={`text-xl font-semibold bg-transparent border-none focus:outline-none rounded-lg p-2 
									${titleError ? 'bg-red-50 text-red-600 placeholder-red-400' : ''}`}
								placeholder="Note title"
							/>
							{titleError && (
								<span className="text-red-600 text-sm px-2">Note must have a title</span>
							)}
						</div>

						<textarea
							value={openNote.content}
							onChange={(e) => {
								const updatedNote = { ...openNote, content: e.target.value };
								setOpenNote(updatedNote);
								debouncedUpdate(updatedNote);
							}}
							className="w-full min-h-[200px] bg-transparent border-none focus:outline-none rounded-lg p-2 resize-none text-neutral-60 focus:text-neutral-10"
							placeholder="Start typing..."
						/>
					</div>
				</div>

				{/* Success Toast */}
				<motion.div
					initial={{ opacity: 0, y: 10 }}
					animate={{
						opacity: showSaved ? 1 : 0,
						y: showSaved ? 0 : 10
					}}
					style={{
						position: 'absolute',
						bottom: '1rem',
						left: '50%',
						x: '-50%'
					}}
				>
					<div className="flex items-center gap-2 bg-green-50 text-green-700 px-3 py-2 rounded-lg border border-green-100">
						<CheckCircle2 className="h-4 w-4" />
						<span className="text-sm">Success!</span>
					</div>
				</motion.div>
			</div>
		);
	}


	return (
		<div className="flex flex-col gap-2">
			<div className="relative">
				<div
					style={{
						backgroundImage: `url('${person.img_url}'), url('/images/user_gray.jpg')`,
					}}
					className="flex justify-between items-end w-full aspect-[7/8] overflow-clip bg-cover bg-center relative"
				/>

				<div className="absolute inset-x-2 bottom-10 text-neutral-10">
					<div className="bg-neutral-95 px-6 py-2 rounded-lg">
						<div className="flex flex-col">
							<span className="font-semibold">{person.first_name} {person.last_name}</span>
							<span>{person.title}{person.subtitle ? ', ' + person.subtitle : ''}</span>
						</div>
					</div>
				</div>
			</div>

			<div className="bg-neutral-95 flex flex-col gap-2 -mt-8 z-10 rounded-3xl pt-4">
				<div className="flex gap-2 w-full">
					<div className="flex w-full">
						<button
							onClick={() => setTab('profile')}
							className={`w-full font-semibold text-neutral-10 pb-2 border-b-2 transition-colors ${tab === 'profile' ? 'border-primary-40' : 'border-transparent'
								}`}
						>
							Profile
						</button>
					</div>
					<div className="flex w-full">
						<button
							onClick={() => setTab('notes')}
							className={`w-full font-semibold text-neutral-10 pb-2 border-b-2 transition-colors ${tab === 'notes' ? 'border-primary-40' : 'border-transparent'
								}`}
						>
							Notes
						</button>
					</div>
				</div>
				<div className='p-2'>
					{{
						'profile': (
							<Profile person={person} copiedTooltip={copiedTooltip} handleCopyClick={handleCopyClick} />
						),
						'notes': <Notes person={person} setOpenNote={(note: Note) => setOpenNote(note)} />
					}[tab]}
				</div>
			</div>
		</div>
	);
}