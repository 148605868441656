import {
    ColumnDef,
    ColumnFilter,
    ColumnFiltersState,
    FilterFn,
    FiltersTableState,
    getCoreRowModel,
    getFacetedMinMaxValues,
    getFacetedRowModel,
    getFacetedUniqueValues,
    getFilteredRowModel,
    getSortedRowModel,
    OnChangeFn,
    Row,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useRef, useState } from 'react';
import hash from 'object-hash';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useTableFilters } from '@contexts';

type CompressedFilters = {
    [key: string]: unknown;
};

export default function useTable<T>(data: T[], mutatedColumns: ColumnDef<T>[]) {
    const { getTableFilter, addTableFilter, removeTableFilter } = useTableFilters();
    const [sorting, setSorting] = useState<SortingState>([]);
    const [globalFilter, setGlobalFilter] = useState();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    const tab = searchParams.get('tab');

    const encodeFilterState = (filters: ColumnFiltersState) => {
        // Transform the filter structure to a more compact format
        const compressed = filters.reduce<CompressedFilters>((acc, filter) => {
            if (filter.value && (filter.value as Array<string>).length > 0) {
                acc[filter.id] = filter.value;
            }
            return acc;
        }, {});

        // Convert to JSON and encode to base64

        return (
            btoa(JSON.stringify(compressed))
                // Make URL-safe
                .replace(/\+/g, '-')
                .replace(/\//g, '_')
                .replace(/=/g, '')
        );
    };

    const decodeFilterState = (encoded: string | undefined): ColumnFiltersState => {
        if (!encoded) return [];

        try {
            // Restore padding if needed
            const padding = '='.repeat((4 - (encoded.length % 4)) % 4);
            const base64 = encoded.replace(/-/g, '+').replace(/_/g, '/') + padding;

            const decoded = JSON.parse(atob(base64));

            // Transform back to ColumnFiltersState structure
            return Object.entries(decoded).map(([id, value]) => ({
                id,
                value,
            }));
        } catch (e) {
            console.error('Error decoding filters:', e);
            return [];
        }
    };

    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const currentFullPath = useMemo(() => {
        return `${location.pathname}${tab ? `?tab=${tab}` : ''}`;
    }, [location.pathname, tab]);

    useEffect(() => {
        const filtersParam = searchParams.get('filters');

        if (filtersParam) {
            setColumnFilters(decodeFilterState(filtersParam));
        }
    }, [searchParams.get('filters')]);

    useEffect(() => {
        if (columnFilters.length > 0) {
            const encoded = encodeFilterState(columnFilters);
            addTableFilter(currentFullPath, encoded);
        }
    }, [columnFilters]);

    const onColumnFilterChange: OnChangeFn<ColumnFiltersState> = (updaterOrValue) => {
        let updatedColumnFilters = updaterOrValue;
        if (typeof updaterOrValue === 'function') {
            updatedColumnFilters = (updaterOrValue(columnFilters) as ColumnFilter[]).filter((filter) => (filter.value as string[]).length > 0);
        }
        setColumnFilters(updatedColumnFilters);
    };

    const customGlobalFilterFn: FilterFn<T> = (row, columnId, filterValue) => {
        const column = table.getColumn(columnId);
        const cellValue = row.getValue(columnId);
        const rowData = row.original;

        if (!column || !cellValue) return false;
        else if (column.columnDef.meta?.search_reference_keys) {
            const { search_reference_keys } = column.columnDef.meta;
            const concatenatedString = search_reference_keys.map((key) => rowData[key as keyof T]).join(' ');
            return concatenatedString.toLowerCase().includes(filterValue.toLowerCase());
        } else {
            return cellValue.toString().toLowerCase().includes(filterValue.toLowerCase());
        }
    };

    const table = useReactTable({
        columns: mutatedColumns,
        data,
        state: {
            sorting,
            globalFilter,
            columnFilters,
        },
        enableColumnPinning: true,
        enableGlobalFilter: true,
        globalFilterFn: customGlobalFilterFn,
        enableHiding: true,
        onGlobalFilterChange: setGlobalFilter,
        onSortingChange: setSorting,
        onColumnFiltersChange: onColumnFilterChange,
        getCoreRowModel: getCoreRowModel<T>(),
        getFilteredRowModel: getFilteredRowModel<T>(),
        getFacetedRowModel: getFacetedRowModel<T>(),
        getSortedRowModel: getSortedRowModel<T>(),
        getFacetedUniqueValues: getFacetedUniqueValues<T>(),
        getFacetedMinMaxValues: getFacetedMinMaxValues<T>(),
        columnResizeMode: 'onChange',
        initialState: {
            columnPinning: {
                left: [],
                right: ['interactions'],
            },
        },
        // getPaginationRowModel: getPaginationRowModel<T>(),
    });

    return {
        table,
    };
}
