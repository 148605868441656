import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

const TableFilterContext = createContext<{
    getTableFilter: (key: string) => string | undefined;
    removeTableFilter: () => void;
    addTableFilter: (key: string, value: string) => void;
}>({
    getTableFilter: (key: string) => undefined,
    removeTableFilter: () => undefined,
    addTableFilter: (key: string, value: string) => undefined,
});

interface Props {
    children: ReactNode;
}

export default function TableFiltersProvider({ children }: Props) {
    const [tableFilters, setTableFilters] = useState<Record<string, string>>({});
    const [searchParams, setSearchParams] = useSearchParams();
    const tab = searchParams.get('tab');
    const table = searchParams.get('table');
    const location = useLocation();

    const currentFullPath = useMemo(() => {
        return `${location.pathname}${tab ? `?tab=${tab}` : ''}`;
    }, [location.pathname, tab]);

    useEffect(() => {
        const filterFromContext = getTableFilter(currentFullPath);
        if (filterFromContext) {
            console.log('hhhh');
            setSearchParams((prev) => {
                prev.set('filters', filterFromContext);
                return prev;
            });
        }
    }, [currentFullPath, table]);

    function getTableFilter(key: string): string | undefined {
        return tableFilters[key];
    }

    function addTableFilter(key: string, value: string) {
        setTableFilters((prev) => {
            prev[key] = value;
            return prev;
        });
        setSearchParams((prev) => {
            prev.set('filters', value);
            return prev;
        });
    }

    function removeTableFilter() {
        setTableFilters((prev) => {
            delete prev[currentFullPath];
            return prev;
        });
        setSearchParams((prev) => {
            prev.delete('filters');
            return prev;
        });
    }

    const value = {
        getTableFilter,
        addTableFilter,
        removeTableFilter,
    };

    return <TableFilterContext.Provider value={value}>{children}</TableFilterContext.Provider>;
}

export function useTableFilters() {
    return useContext(TableFilterContext);
}
