import { Column, ColumnFiltersState } from '@tanstack/react-table';
import { XCircleIcon } from '@heroicons/react/16/solid';
import { useLocale } from '@react-aria/i18n';
import { useTableFilters } from '@contexts';

interface ActiveFiltersProps<T> {
    columns: Column<T>[];
    filters: ColumnFiltersState;
}

export default function ActiveFilters<T>({ columns, filters }: ActiveFiltersProps<T>) {
    const locale = useLocale();
    const dateToString = (
        date: any,
        options = {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        } as Intl.DateTimeFormatOptions
    ) => {
        return new Intl.DateTimeFormat(locale.locale, options).format(date.toDate(Intl.DateTimeFormat().resolvedOptions().timeZone));
    };

    const { removeTableFilter } = useTableFilters();

    return (
        <div className={'w-full sm:ml-5 sm:overflow-x-auto pb-1'}>
            <div className="flex-wrap sm:flex-nowrap flex gap-2">
                {columns.map((column) => {
                    if (column.getIsFiltered()) {
                        const filterValue = column.getFilterValue() as string[];
                        if (column.columnDef.meta?.data_type === 'range' || column.columnDef.meta?.data_type === 'int') {
                            return (
                                <div
                                    key={column.id}
                                    className={
                                        'flex items-center gap-2 px-2 py-1 bg-primary-50 border border-primary-70 text-neutral-90 rounded-full'
                                    }
                                >
                                    <span>
                                        {filterValue[0]} - {filterValue[1]}
                                    </span>
                                    <XCircleIcon
                                        className={'h-4 cursor-pointer'}
                                        onClick={() => {
                                            column.setFilterValue([]);
                                            if (filters.length === 1) {
                                                removeTableFilter();
                                            }
                                        }}
                                    />
                                </div>
                            );
                        } else if (column.columnDef.meta?.data_type === 'date') {
                            return (
                                <div
                                    key={column.id}
                                    className={
                                        'flex items-center gap-2 px-2 py-1 bg-primary-50 text-neutral-90 border border-primary-60 rounded-full'
                                    }
                                >
                                    <span>
                                        {dateToString(filterValue[0])} - {dateToString(filterValue[1])}
                                    </span>
                                    <XCircleIcon
                                        className={'h-4 cursor-pointer'}
                                        onClick={() => {
                                            column.setFilterValue([]);
                                            if (filters.length === 1) {
                                                removeTableFilter();
                                            }
                                        }}
                                    />
                                </div>
                            );
                        }
                        return filterValue.map((filterValue, index) => {
                            return (
                                <div
                                    key={index}
                                    className={
                                        'flex items-center gap-2 px-2 py-1  bg-primary-50 text-neutral-90 border border-primary-60 rounded-full '
                                    }
                                >
                                    <span className="text-nowrap max-w-32 truncate">{filterValue ?? '—'}</span>
                                    <XCircleIcon
                                        className={'h-4 cursor-pointer flex-shrink-0'}
                                        onClick={() => {
                                            const currentFilters = column?.getFilterValue() ? [...(column?.getFilterValue() as string[])] : [];
                                            column.setFilterValue(currentFilters.filter((item) => item !== filterValue));
                                            if (filters.length === 1) {
                                                removeTableFilter();
                                            }
                                        }}
                                    />
                                </div>
                            );
                        });
                    }
                    return null;
                })}
            </div>
        </div>
    );
}
